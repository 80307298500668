import React, { useEffect, useState } from "react";
import { fetchProducts } from "../action/especificos/FutbolistaActions";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Animated } from "react-animated-css";
import iconclub from "../assets/imagenes/fans-luque/especificas/icon.png";
import { useTime } from "react-timer-hook";
import Timer from "react-compound-timer";
import moment from "moment";


function TablaComponent() {
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const dispatch = useDispatch();
  const encuentros = useSelector((state) => state.products);
  const [load, setLoad] = useState(true);
  const [loadCrono, setLoadCrono] = useState(true);
  const [cronometro, setCronometro] = useState(0);

  useEffect(() => {

    if (load) {
      dispatch(fetchProducts());
      setLoad(false);
    }
  }, []);
  const { seconds, minutes, hours, ampm } = useTime({ format: "24-hour" });

  let inicioCal;
  let display;
  let hora;

  if (encuentros.items) {
    if (loadCrono == true) {
      const inicio = encuentros.items.encuentro.inicio_cal_encuentro;
      const tiempoCrono = encuentros.items.encuentro.tiempo_cal_encuentro;
      const fechaServer = encuentros.items.fechaActual;
      inicioCal = moment(inicio).add(tiempoCrono, "hours");
      setCronometro(inicioCal.diff(fechaServer));

      setLoadCrono(false);
    }
    if(encuentros.items.encuentro.estado_actual_encuentro == 1){
      hora = (encuentros.items.encuentro.hora_encuentro).split(":");
      hora = hora[0]+":"+hora[1]
      display ="flex"
    } else {
      display ="none"
      // hora = (encuentros.items.encuentro.hora_encuentro).split(":");
      // hora = hora[0]+":"+hora[1]
    }
  }


  return (
    <React.Fragment>
      {!encuentros.items ? (
        <Slider />
      ) : encuentros.items.encuentro.length === 0 ? (
        <div></div>
      ) : (
        <Animated
          animationIn="flipInY"
          animationOut="flipOutY"
          animationInDuration={3000}
          animationOutDuration={3000}
          isVisible={true}
        >
          <div className="cuadro-verde">
            <div className="cuadro">
              <div className="cuadro">
                {encuentros.items.encuentro.cancha_encuentro === "local" ? (
                  <div className="cuadro">
                    <img src={iconclub} alt="club" className="club" />
                    <h1 className="gol">
                      {encuentros.items.encuentro.res_favor_encuentro}
                    </h1>
                  </div>
                ) : (
                  <div className="cuadro">
                    <img
                      src={`${config_server.linkImageClub}${encuentros.items.encuentro.logo_club}`}
                      alt="club"
                      className="club"
                    />
                    <h1 className="gol">
                      {encuentros.items.encuentro.res_contra_encuentro}
                    </h1>
                  </div>
                )}
              </div>
              <div>
                <h2 className="color-white h1">Vs</h2>
              </div>
              <div className="cuadro">
                {encuentros.items.encuentro.cancha_encuentro === "visitante" ? (
                  <div className="cuadro">
                    <h1 className="gol">
                      {encuentros.items.encuentro.res_favor_encuentro}
                    </h1>
                    <img src={iconclub} alt="club" className="club" />
                  </div>
                ) : (
                  <div>
                    <div className="cuadro">
                      <h1 className="gol">
                        {encuentros.items.encuentro.res_contra_encuentro}
                      </h1>
                      <img
                        src={`${config_server.linkImageClub}${encuentros.items.encuentro.logo_club}`}
                        alt="club"
                        className="club"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <h2 className="centrar datos-table">
              Fecha:{" "}
              {encuentros.items.encuentro.dia_encuentro
                .split(" ")[0]
                .split("-")
                .reverse()
                .join("-")}
            </h2>
            <h2 className="centrar datos-table">
              Hora de inicio:{" "}
              {hora}
            </h2>
          </div>

          <div className="centrar time-div" style={{ display: display }}>
            <h2 className="time">
              <Timer
                initialTime={cronometro}
                direction="backward"
                checkpoints={[
                  {
                    time: 0,
                    callback: () => window.location.reload("/"),
                  },
                ]}
              >
                {() => (
                  <React.Fragment>
                    <Timer.Hours />:
                    <Timer.Minutes />:
                    <Timer.Seconds />
                  </React.Fragment>
                )}
              </Timer>
            </h2>
          </div>
          <div>
              <h6 className="centrar p">
              Tiene 24hs para calificar
              </h6>
          </div>
        </Animated>
      )}
    </React.Fragment>
  );
}

export default TablaComponent;
